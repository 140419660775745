import { useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ERPNEXT_API_KEY,
  ERPNEXT_SECRET_API_KEY,
  ERP_ENDPOINT,
} from "../../constants/env";
import {
  IProjectListState,
  IRecordsTableProps,
  ITimeRecord,
} from "../../constants/types";

import axios from "axios";
import moment from "moment";

export const useRecordsTable = ({
  currentWeek,
  projectList,
  allProjectsList,
  projectsModalOpenHandler,
  setIsLoading,
  setProjectList,
}: IRecordsTableProps) => {
  const toast = useToast();
  const {
    isOpen: isOthersProjectModalOpen,
    onOpen: onOthersProjectModalOpen,
    onClose: onOthersProjectModalClose,
  } = useDisclosure();

  const [timeRecords, setTimeRecords] = useState<ITimeRecord[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: number }>({});
  const [othersProjectNoteValues, setOthersProjectNoteValues] = useState<{
    [key: string]: string;
  }>({});
  const [remoteChecksValues, setRemoteChecksValues] = useState<{
    [key: string]: boolean;
  }>({});
  const [activeInput, setActiveInput] = useState<string>("");

  useEffect(() => {
    getTimesheetRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek]);

  const getInputValue = (projectCode: string, date: string) => {
    const projectRecords = timeRecords.filter(
      (r) => r["Código proyecto"] === projectCode
    );
    const record = projectRecords.find(
      (r) => r.Fecha === moment(date).format("YYYY-MM-DD")
    );

    return record?.["Número de horas"];
  };

  const getDayTotalHours = (date: string) => {
    const dayRecords = timeRecords.filter((r) => r.Fecha === date);

    const totalHours = dayRecords.reduce(
      (previous, current) => previous + current["Número de horas"],
      0
    );

    return totalHours;
  };

  const getRemoteCheckValue = (date: string) => {
    if (remoteChecksValues[date]) return remoteChecksValues[date];

    const dayRecordsRemotes = timeRecords
      .filter((r) => r.Fecha === moment(date).format("YYYY-MM-DD"))
      .map((r) => r["Remoto"]);

    if (dayRecordsRemotes.length === 0) return undefined;

    const result = dayRecordsRemotes.some((i) => i === true);

    return result;
  };

  const onSentChangeHandler = async (
    action: "send" | "delete",
    date: string
  ) => {
    if (action === "send") {
      const dayReports = Object.keys(inputValues).map((key) => ({
        name: key,
        value: inputValues[key],
      }));
      console.log("dayReports", dayReports);

      const hours_total = dayReports.reduce(
        (sum, li) => sum + (li.value || 0),
        0
      );
      console.log("hours_total", hours_total);

      const addedDate = new Date(date);
      addedDate.setHours(8, 0, 0, 0);

      setIsLoading(true);

      for (let i = 0; i < dayReports.length; i++) {
        const project = dayReports[i].name.split("_")[0];
        const hours = dayReports[i].value;

        if (!isNaN(hours) && hours > 0) {
          axios
            .post(
              `${ERP_ENDPOINT}/api/resource/Timesheet`,
              {
                employee: sessionStorage.getItem("userEmpId"),
                parent_project: project,
                note: othersProjectNoteValues[dayReports[i].name] || "",
                remote: +remoteChecksValues[date] || 0,
                time_logs: [
                  {
                    activity_type: "Ejecución",
                    from_time: new Date(addedDate)
                      .toISOString()
                      .slice(0, 19)
                      .replace("T", " "),
                    hours: hours,
                    project,
                    is_billable: 1,
                  },
                ],
              },
              {
                headers: {
                  Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
                },
              }
            )
            .then((res) => {
              console.log(res.data);

              getTimesheetRecords();
              window.location.reload();
            })
            .catch((err) => {
              console.error(err);
              setIsLoading(false);

              toast({
                title: "Error",
                description: `Ha ocurrido un error enviando el reporte de horas.
                  Por favor, comunícalo a jaime.dordio@dordio.es`,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        }
      }
    } else {
    }
  };

  const getTimesheetRecords = () => {
    let newProjectList: IProjectListState[] = [];
    let newTimeRecords: ITimeRecord[] = [];

    if (currentWeek) {
      axios
        .get(`${ERP_ENDPOINT}/api/resource/Timesheet`, {
          params: {
            filters: `[["employee", "=", "${sessionStorage.getItem(
              "userEmpId"
            )}"], ["status", "!=", "cancelled"]]`,
            fields: `["parent_project", "start_date", "total_hours", "note", "remote"]`,
            limit_page_length: 1000,
          },
          headers: {
            Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
          },
        })
        .then((res) => {
          console.log("Employee timesheets: ", res.data);

          res.data.data.forEach((timesheet: any) => {
            newTimeRecords.push({
              "Código proyecto": timesheet.parent_project,
              Fecha: timesheet.start_date,
              "Número de horas": timesheet.total_hours,
              Nota: timesheet.note,
              Remoto: Boolean(timesheet.remote),
            });
          });

          const filterStartDate = moment(currentWeek[0].completeDate)
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          const filterEndDate = moment(
            currentWeek[currentWeek.length - 1].completeDate
          ).format("YYYY-MM-DD");

          newTimeRecords = newTimeRecords.filter((item) => {
            const formattedDate = moment(item.Fecha).format("YYYY-MM-DD");
            return (
              formattedDate >= filterStartDate && formattedDate <= filterEndDate
            );
          });

          newProjectList = allProjectsList.filter((item) =>
            newTimeRecords.map((r) => r["Código proyecto"]).includes(item.name)
          );

          setProjectList(newProjectList);
          setTimeRecords(newTimeRecords);
        });
    }
  };

  const inputChangeHandler: (
    project: any,
    day: any,
    newValue: string
  ) => void = (project, day, newValue) => {
    let newInputValues = {
      ...inputValues,
    };
    let key = `${project.name}_${day.completeDate}`;
    newInputValues[key] = parseInt(newValue);

    setActiveInput(`${project.name}_${day.completeDate}`);
    setInputValues(newInputValues);

    if (
      project.project_name === "Otros" &&
      newValue !== "" &&
      newValue !== "0"
    ) {
      console.log("Change on project 'Otros'");
      console.log(newValue);

      onOthersProjectModalOpen();
    }
  };

  return {
    activeInput,
    setActiveInput,
    remoteChecksValues,
    setRemoteChecksValues,
    othersProjectNoteValues,
    setOthersProjectNoteValues,
    getDayTotalHours,
    getInputValue,
    getRemoteCheckValue,
    inputChangeHandler,
    onSentChangeHandler,
    isOthersProjectModalOpen,
    onOthersProjectModalClose,
  };
};
