import { NumberInput, NumberInputField, Td } from "@chakra-ui/react";

import { IRecordsTableBodyDayInputProps } from "../../constants/types";

const RecordsTableBodyDayInput = ({
  project,
  day,
  getDayTotalHours,
  getInputValue,
  setActiveInput,
  inputChangeHandler,
}: IRecordsTableBodyDayInputProps) => {
  return (
    <>
      <Td
        maxW={"fit-content"}
        key={`day-input-${project.name}_${day.completeDate}`}
      >
        <NumberInput
          name={`${project.name}_${day.completeDate}`}
          size={"sm"}
          min={0}
          max={8}
          step={1}
          isDisabled={
            day.weekday === "Sab" ||
            day.weekday === "Dom" ||
            getInputValue(project.name, day.completeDate) !== undefined
          }
          value={getInputValue(project.name, day.completeDate)}
          onFocus={() => setActiveInput(`${project.name}_${day.completeDate}`)}
          onChange={(e) => inputChangeHandler(project, day, e)}
        >
          <NumberInputField
            size={4}
            pl={0}
            pr={0}
            textAlign="center"
            borderColor={"gray.400"}
          />
        </NumberInput>
      </Td>
    </>
  );
};

export default RecordsTableBodyDayInput;
