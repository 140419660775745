import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Button,
  Container,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ERPNEXT_API_KEY,
  ERPNEXT_SECRET_API_KEY,
  ERP_ENDPOINT,
} from "./constants/env";

import { SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import MicrosoftLogin from "react-microsoft-login";
import { images } from "./assets/images/images";
import ReportingMain from "./components/ReportingMain/ReportingMain";
import { IProjectListState } from "./constants/types";

const App = () => {
  /*
   *
   * HOOKS
   *
   */
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<IProjectListState[]>([]);
  const [allProjectsList, setAllProjectsList] = useState<
    IProjectListState[] | null
  >();
  const [modalSearchValue, setModalSearchValue] = useState<string>("");

  /*
   *
   * EFFECTS
   *
   */
  useEffect(() => {
    if (sessionStorage.getItem("msal.idtoken")) {
      getProjectsList();
    }
  }, []);

  /*
   *
   * HANDLERS
   *
   */
  const authHandler = (err: any, data: any) => {
    console.log(err, data);

    sessionStorage.setItem("name", data.account.name);
    sessionStorage.setItem("userName", data.account.userName);

    axios
      .get(
        `${ERP_ENDPOINT}/api/resource/Employee?filters=[["user_id", "=", "${data.account.userName}"]]`,
        {
          headers: {
            Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
          },
        }
      )
      .then((res) => {
        sessionStorage.setItem("userEmpId", res.data.data[0].name);
        window.location.reload();
      });
  };

  const getProjectsList = () => {
    setIsLoading(true);

    axios
      .get(
        `${ERP_ENDPOINT}/api/resource/Project?fields=["name","project_name","customer"]&filters=[["status", "!=", "Cancelled"]]`,
        {
          params: {
            limit_page_length: 1000,
          },
          headers: {
            Authorization: `token ${ERPNEXT_API_KEY}:${ERPNEXT_SECRET_API_KEY}`,
          },
        }
      )
      .then((res) => {
        console.log("Projects list: ", res.data);
        setAllProjectsList(res.data.data);
        setIsLoading(false);
      });
  };

  const projectsModalOpenHandler = () => {
    onOpen();
  };

  const projectAddHandler = (newProject: IProjectListState) => {
    let newProjectList = projectList;

    newProjectList.push(newProject);
    setProjectList(newProjectList);

    onClose();
  };

  return (
    <>
      <Container maxW="container.xl" my={10} p={6}>
        <Stack direction={"column"} gap={12}>
          <Stack direction={"column"} gap={5}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Image src={images.logo} w="300px" />
              {sessionStorage.getItem("msal.idtoken") ? (
                <Stack direction={"row"} alignItems="center" gap={1} mr={4}>
                  <Avatar bg="teal.500" size={"sm"} />
                  <Stack direction={"column"} alignItems="start">
                    <Text fontSize="sm" fontWeight={"bold"}>
                      {sessionStorage.getItem("name")}
                    </Text>
                    <Text
                      fontSize="xs"
                      color={"GrayText"}
                      style={{ marginTop: "2px" }}
                    >
                      {sessionStorage.getItem("userName")}
                    </Text>
                    <Text
                      fontSize="xs"
                      color={"GrayText"}
                      style={{ marginTop: "2px" }}
                    >
                      {sessionStorage.getItem("userEmpId")}
                    </Text>
                  </Stack>
                </Stack>
              ) : (
                ""
              )}
            </Stack>

            <Text fontSize="2xl" fontWeight={"bold"}>
              Reporting
            </Text>
          </Stack>
          {isLoading ? (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          ) : !sessionStorage.getItem("msal.idtoken") ? (
            <MicrosoftLogin
              clientId={"15ffdc23-9a8c-44eb-8b0c-00663632673f"}
              authCallback={authHandler}
            />
          ) : !sessionStorage.getItem("userEmpId") ? (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="fit-content"
              py={8}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                ¡Error!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                No existe ningún empleado con ese correo electrónico. Si
                consideras que es un error, por favor comunícalo a
                jaime.dordio@dordio.es
              </AlertDescription>
            </Alert>
          ) : (
            allProjectsList && (
              <ReportingMain
                projectsModalOpenHandler={projectsModalOpenHandler}
                projectList={projectList}
                allProjectsList={allProjectsList}
                setProjectList={setProjectList}
                setIsLoading={setIsLoading}
              />
            )
          )}
        </Stack>
      </Container>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        isCentered
        blockScrollOnMount
        orientation="horizontal"
      >
        <ModalOverlay />
        <ModalContent maxH={"90vh"}>
          <ModalHeader>Añadir nuevo trabajo</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4} overflowY={"scroll"}>
            <Stack
              direction={"column"}
              divider={<StackDivider borderColor="gray.300" />}
              spacing={6}
              maxH={"inherit"}
            >
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="text"
                  placeholder="Buscar..."
                  onChange={(e) => setModalSearchValue(e.currentTarget.value)}
                />
              </InputGroup>
              <Stack
                divider={<StackDivider borderColor="gray.200" />}
                overflowY={"scroll"}
              >
                {allProjectsList
                  ?.filter(
                    (p) =>
                      p.customer
                        .toLowerCase()
                        .includes(modalSearchValue.toLowerCase()) ||
                      p.name
                        .toLowerCase()
                        .includes(modalSearchValue.toLowerCase()) ||
                      p.project_name
                        .toLowerCase()
                        .includes(modalSearchValue.toLowerCase())
                  )
                  .map((project, index) => (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      key={`project-modal-${index}`}
                    >
                      <Stack gap={0}>
                        <Text fontSize="xs">{project.name}</Text>
                        <Text style={{ marginTop: "2px" }} fontWeight="bold">
                          {project.project_name}
                        </Text>
                        <Text style={{ marginTop: "0" }}>
                          {project.customer}
                        </Text>
                      </Stack>
                      <Button
                        colorScheme="blue"
                        variant="outline"
                        rounded={"md"}
                        size="sm"
                        minW={"auto"}
                        onClick={() => projectAddHandler(project)}
                        isDisabled={Boolean(
                          projectList.find((p) => p.name === project.name)
                        )}
                      >
                        Añadir
                      </Button>
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default App;
