import { Th, Tr } from "@chakra-ui/react";

import { IRecordsTableHeaderProps } from "../../constants/types";

const RecordsTableHeader = ({ currentWeek }: IRecordsTableHeaderProps) => {
  return (
    <>
      <Tr>
        <Th>Trabajo</Th>
        <Th colSpan={9} textAlign="center">
          Fecha
        </Th>
      </Tr>
      <Tr bgColor={"gray.100"}>
        <Th></Th>
        {currentWeek
          ? currentWeek.map((day, index) => (
              <Th
                textAlign={"center"}
                key={`day-${index}`}
                bgColor={
                  day.weekday === "Sab" || day.weekday === "Dom"
                    ? "gray.200"
                    : ""
                }
              >
                {day.date} <br /> {day.weekday}
              </Th>
            ))
          : ""}
      </Tr>
    </>
  );
};

export default RecordsTableHeader;
