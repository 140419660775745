export const PROXY = "https://cors-anywhere-jaimedordio.herokuapp.com";
export const ERP_ENDPOINT = "https://erp.dordio.es";
export const PROXIED_ENDPOINT = `${PROXY}/${ERP_ENDPOINT}`;

export const AIRTABLE_API_KEY: string = "keyEPPKlA2buA8CmU";
export const ERPNEXT_API_KEY: string = "71fdb6920daddd5";
export const ERPNEXT_SECRET_API_KEY: string = "b96b4722c6c7122";

interface IWeekdaysTranslate {
  [key: string]: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sun: string;
}

export const weekdaysTranslate: IWeekdaysTranslate = {
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mie",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sab",
  Sun: "Dom",
};
