import { IconButton, Th, Tr } from "@chakra-ui/react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { IRecordsTableFooterSubmitProps } from "../../constants/types";

const RecordsTableFooterSubmit = ({
  currentWeek,
  onSentChangeHandler,
  getDayTotalHours,
}: IRecordsTableFooterSubmitProps) => {
  return (
    <>
      <Tr>
        <Th textAlign="end">Enviar</Th>
        {currentWeek
          ? currentWeek.map((day) => (
              <Th key={`day-total-sent-${day.completeDate}`} textAlign="center">
                {day.weekday === "Sab" || day.weekday === "Dom" ? (
                  ""
                ) : (
                  <IconButton
                    aria-label="Enviar registro"
                    icon={<ArrowForwardIcon />}
                    variant="outline"
                    colorScheme="blue"
                    size="sm"
                    onClick={() =>
                      onSentChangeHandler("send", day.completeDate)
                    }
                  />
                )}
              </Th>
            ))
          : ""}
      </Tr>
    </>
  );
};

export default RecordsTableFooterSubmit;
