import { ArrowBackIcon, ArrowForwardIcon, Icon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";

import moment from "moment";
import { IReportingMainProps } from "../../constants/types";
import RecordsTable from "../RecordsTable/RecordsTable";
import { useReportingMain } from "./useReportingMain";

const ReportingMain = (props: IReportingMainProps) => {
  const {
    currentWeek,
    onMissingProjectModalOpen,
    isMissingProjectModalOpen,
    onMissingProjectModalClose,
    projectRequestFormHandler,
    setQuotationIdInput,
    quotationIdInputInvalid,
    setProjectNameInput,
    projectNameInputInvalid,
    projectRequestLoading,
    weekChangeHandler,
  } = useReportingMain(props);

  return (
    <>
      <Stack gap={3}>
        <Stack direction={"row"} justifyContent="end">
          <Button
            variant="outline"
            rounded={"md"}
            size="sm"
            onClick={() => weekChangeHandler("today")}
          >
            Ir a 'Hoy'
          </Button>
          <ButtonGroup size="sm" variant="outline" alignItems={"center"}>
            <IconButton
              aria-label="Semana anterior"
              icon={<ArrowBackIcon />}
              onClick={() => weekChangeHandler("prev")}
            />
            <>
              {currentWeek ? (
                <Text
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  letterSpacing={"wide"}
                >
                  {moment(currentWeek[0].completeDate).format("DD/MM/YYYY")} -{" "}
                  {moment(
                    currentWeek[currentWeek.length - 1].completeDate
                  ).format("DD/MM/YYYY")}
                </Text>
              ) : (
                <></>
              )}
            </>
            <IconButton
              aria-label="Semana siguiente"
              icon={<ArrowForwardIcon />}
              onClick={() => weekChangeHandler("next")}
            />
          </ButtonGroup>
        </Stack>
        <RecordsTable
          currentWeek={currentWeek}
          projectList={props.projectList}
          allProjectsList={props.allProjectsList}
          projectsModalOpenHandler={props.projectsModalOpenHandler}
          setIsLoading={props.setIsLoading}
          setProjectList={props.setProjectList}
        />
        <Stack direction={"row"} justifyContent="start" alignItems={"center"}>
          <Button
            leftIcon={<Icon />}
            colorScheme="gray"
            variant="link"
            fontWeight={"normal"}
            outline="none"
            boxShadow={"none"}
            _focus={{
              boxShadow: "none",
            }}
            onClick={() => onMissingProjectModalOpen()}
          >
            ¿No ves el trabajo que buscas?
          </Button>
        </Stack>
      </Stack>
      <Modal
        isOpen={isMissingProjectModalOpen}
        onClose={onMissingProjectModalClose}
        size="md"
        isCentered
        blockScrollOnMount
        orientation="horizontal"
      >
        <ModalOverlay />
        <ModalContent maxH={"90vh"}>
          <ModalHeader>Solicitar nuevo trabajo</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <form onSubmit={projectRequestFormHandler}>
              <Stack direction={"column"} spacing={6} maxH={"inherit"}>
                <FormControl
                  id="quotation_id"
                  isRequired
                  isInvalid={quotationIdInputInvalid}
                >
                  <FormLabel>Referencia oferta</FormLabel>
                  <Input
                    type="text"
                    onChange={(e) => setQuotationIdInput(e.target.value)}
                  />
                  {quotationIdInputInvalid && (
                    <FormErrorMessage>
                      La referencia de la oferta es obligatoria
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="project_name"
                  isRequired
                  isInvalid={projectNameInputInvalid}
                >
                  <FormLabel>Nombre del trabajo</FormLabel>
                  <Input
                    type="text"
                    onChange={(e) => setProjectNameInput(e.target.value)}
                  />
                  {projectNameInputInvalid && (
                    <FormErrorMessage>
                      Un nombre para el trabajo es obligatorio
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  loadingText="Enviando..."
                  isLoading={projectRequestLoading}
                  size="md"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Enviar
                </Button>
              </Stack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportingMain;
