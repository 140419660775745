import { Th, Tr } from "@chakra-ui/react";

import moment from "moment";
import { IRecordsTableFooterTotalIntroducedProps } from "../../constants/types";

const RecordsTableFooterTotalIntroduced = ({
  currentWeek,
  getDayTotalHours,
}: IRecordsTableFooterTotalIntroducedProps) => {
  return (
    <>
      <Tr>
        <Th textAlign="end">Total introducido</Th>
        {currentWeek
          ? currentWeek.map((day, index) => (
              <Th key={`day-total-introduced-${index}`} textAlign={"center"}>
                {getDayTotalHours(
                  moment(day.completeDate).format("YYYY-MM-DD")
                )}
              </Th>
            ))
          : ""}
      </Tr>
    </>
  );
};

export default RecordsTableFooterTotalIntroduced;
