import da_logo from "./files/da-logo.svg";

interface IImages {
  [key: string]: string | object;
  logo: string;
}

export const images: IImages = {
  logo: da_logo,
};
