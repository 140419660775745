import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { IRecordsTableProps } from "../../constants/types";
import ModalOthersReason from "../ModalOthersReason/ModalOthersReason";
import RecordsTableBodyDayInput from "../RecordsTableBodyDayInput/RecordsTableBodyDayInput";
import RecordsTableFooterRemote from "../RecordsTableFooterRemote/RecordsTableFooterRemote";
import RecordsTableFooterSubmit from "../RecordsTableFooterSubmit/RecordsTableFooterSubmit";
import RecordsTableFooterTotalIntroduced from "../RecordsTableFooterTotalIntroduced/RecordsTableFooterTotalIntroduced";
import RecordsTableHeader from "../RecordsTableHeader/RecordsTableHeader";
import { useRecordsTable } from "./useRecordsTable";

const RecordsTable = ({
  currentWeek,
  projectList,
  allProjectsList,
  projectsModalOpenHandler,
  setIsLoading,
  setProjectList,
}: IRecordsTableProps) => {
  const {
    activeInput,
    setActiveInput,
    remoteChecksValues,
    setRemoteChecksValues,
    othersProjectNoteValues,
    setOthersProjectNoteValues,
    getDayTotalHours,
    getInputValue,
    getRemoteCheckValue,
    inputChangeHandler,
    onSentChangeHandler,
    isOthersProjectModalOpen,
    onOthersProjectModalClose,
  } = useRecordsTable({
    currentWeek,
    projectList,
    allProjectsList,
    projectsModalOpenHandler,
    setIsLoading,
    setProjectList,
  });

  return (
    <>
      <TableContainer>
        <Table variant="simple" colorScheme="gray">
          <Thead>
            <RecordsTableHeader currentWeek={currentWeek} />
          </Thead>
          <Tbody fontSize="sm">
            {projectList
              ? projectList.map((project, index) => (
                  <Tr key={`project-${index}`}>
                    <Td>
                      <b>{project.project_name}</b> <br />
                      {project.customer}
                    </Td>
                    {currentWeek
                      ? currentWeek.map((day) => (
                          <>
                            <RecordsTableBodyDayInput
                              project={project}
                              day={day}
                              getDayTotalHours={getDayTotalHours}
                              getInputValue={getInputValue}
                              setActiveInput={setActiveInput}
                              inputChangeHandler={inputChangeHandler}
                            />
                          </>
                        ))
                      : ""}
                  </Tr>
                ))
              : ""}
            <Tr>
              <Th p={2}>
                <Button
                  rightIcon={<AddIcon />}
                  colorScheme="gray"
                  variant="outline"
                  rounded={"md"}
                  size="sm"
                  isFullWidth
                  onClick={projectsModalOpenHandler}
                >
                  Añadir trabajo
                </Button>
              </Th>
            </Tr>
          </Tbody>
          <Tfoot>
            <RecordsTableFooterRemote
              currentWeek={currentWeek}
              remoteChecksValues={remoteChecksValues}
              setRemoteChecksValues={setRemoteChecksValues}
              getRemoteCheckValue={getRemoteCheckValue}
            />
            <RecordsTableFooterTotalIntroduced
              currentWeek={currentWeek}
              getDayTotalHours={getDayTotalHours}
            />
            <RecordsTableFooterSubmit
              currentWeek={currentWeek}
              onSentChangeHandler={onSentChangeHandler}
              getDayTotalHours={getDayTotalHours}
            />
          </Tfoot>
        </Table>
      </TableContainer>
      <ModalOthersReason
        isOthersProjectModalOpen={isOthersProjectModalOpen}
        onOthersProjectModalClose={onOthersProjectModalClose}
        activeInput={activeInput}
        othersProjectNoteValues={othersProjectNoteValues}
        setOthersProjectNoteValues={setOthersProjectNoteValues}
      />
    </>
  );
};

export default RecordsTable;
