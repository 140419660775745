import { Checkbox, Th, Tr } from "@chakra-ui/react";

import { IRecordsTableFooterRemoteProps } from "../../constants/types";

const RecordsTableFooterRemote = ({
  currentWeek,
  remoteChecksValues,
  setRemoteChecksValues,
  getRemoteCheckValue,
}: IRecordsTableFooterRemoteProps) => {
  return (
    <>
      <Tr>
        <Th textAlign="end">Teletrabajo</Th>
        {currentWeek
          ? currentWeek.map((day, index) => (
              <Th key={`day-remote-${index}`} textAlign={"center"}>
                <Checkbox
                  isDisabled={
                    day.weekday === "Sab" ||
                    day.weekday === "Dom" ||
                    getRemoteCheckValue(day.completeDate) !== undefined
                  }
                  isChecked={getRemoteCheckValue(day.completeDate)}
                  onChange={(event) => {
                    let newRemoteChecksValues = {
                      ...remoteChecksValues,
                    };
                    newRemoteChecksValues[`${day.completeDate}`] =
                      event.target.checked;

                    setRemoteChecksValues(newRemoteChecksValues);
                  }}
                />
              </Th>
            ))
          : ""}
      </Tr>
    </>
  );
};

export default RecordsTableFooterRemote;
