import { useDisclosure, useToast } from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { ICurrentWeekState, IReportingMainProps } from "../../constants/types";

import emailjs from "@emailjs/browser";
import moment from "moment";
import { weekdaysTranslate } from "../../constants/env";

export const useReportingMain = (props: IReportingMainProps) => {
  const toast = useToast();

  const [quotationIdInput, setQuotationIdInput] = useState<string>("");
  const [projectNameInput, setProjectNameInput] = useState<string>("");
  const quotationIdInputInvalid = quotationIdInput === "";
  const projectNameInputInvalid = projectNameInput === "";

  const [projectRequestLoading, setProjectRequestLoading] =
    useState<boolean>(false);

  const [currentWeek, setCurrentWeek] = useState<
    ICurrentWeekState[] | undefined
  >();
  const {
    isOpen: isMissingProjectModalOpen,
    onOpen: onMissingProjectModalOpen,
    onClose: onMissingProjectModalClose,
  } = useDisclosure();

  useEffect(() => {
    const currentWeek = [];

    let startOfPeriod = moment();
    const begin = moment(startOfPeriod).isoWeekday(1);

    for (let i = 0; i < 7; i++) {
      currentWeek.push({
        date: begin.date(),
        weekday: weekdaysTranslate[begin.format("ddd")],
        completeDate: begin.toISOString(),
      });

      begin.add(1, "d");
    }

    setCurrentWeek(currentWeek);
  }, []);

  const weekChangeHandler = (way: "today" | "prev" | "next") => {
    const newCurrentWeek = [];

    if (currentWeek) {
      let startOfPeriod = moment();

      if (way === "prev") {
        startOfPeriod = moment(currentWeek[0].completeDate).add(-1, "d");
      }
      if (way === "next") {
        startOfPeriod = moment(
          currentWeek[currentWeek.length - 1].completeDate
        ).add(1, "d");
      }

      const begin = moment(startOfPeriod).isoWeekday(1);

      for (let i = 0; i < 7; i++) {
        newCurrentWeek.push({
          date: begin.date(),
          weekday: weekdaysTranslate[begin.format("ddd")],
          completeDate: begin.toISOString(),
        });

        begin.add(1, "d");
      }

      setCurrentWeek(newCurrentWeek);
    }
  };

  const projectRequestFormHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setProjectRequestLoading(true);

    emailjs
      .send(
        "emailjs_office_da",
        "template_p_reporting",
        {
          user: sessionStorage.getItem("userName"),
          quotation_id: quotationIdInput,
          project_name: projectNameInput,
        },
        "Tju9UDZbRkl-JL5br"
      )
      .then(
        (result) => {
          toast({
            title: "Trabajo solicitado",
            description: `Se ha solicitado el trabajo, pronto estará disponible en la herramienta`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          onMissingProjectModalClose();
        },
        (error) => {
          toast({
            title: "Error",
            description: `Se ha producido un error solicitando el trabajo. Por favor, inténtelo de nuevo`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          console.error("Error requesting project");
          console.error(error);
        }
      )
      .finally(() => setProjectRequestLoading(false));
  };

  return {
    currentWeek,
    onMissingProjectModalOpen,
    isMissingProjectModalOpen,
    onMissingProjectModalClose,
    projectRequestFormHandler,
    setQuotationIdInput,
    quotationIdInputInvalid,
    setProjectNameInput,
    projectNameInputInvalid,
    projectRequestLoading,
    weekChangeHandler,
  };
};
