import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";

import { IModalOthersReasonProps } from "../../constants/types";

const ModalOthersReason = ({
  isOthersProjectModalOpen,
  onOthersProjectModalClose,
  activeInput,
  othersProjectNoteValues,
  setOthersProjectNoteValues,
}: IModalOthersReasonProps) => {
  return (
    <Modal
      isOpen={isOthersProjectModalOpen}
      onClose={onOthersProjectModalClose}
      closeOnOverlayClick={false}
      size="md"
      isCentered
      blockScrollOnMount
      orientation="horizontal"
    >
      <ModalOverlay />
      <ModalContent maxH={"90vh"}>
        <ModalHeader>Justificación 'Otros'</ModalHeader>
        <ModalBody mb={4}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onOthersProjectModalClose();
            }}
          >
            <Stack direction={"column"} spacing={6} maxH={"inherit"}>
              <FormControl
                id="others_project_note"
                isRequired
                isInvalid={othersProjectNoteValues[activeInput] === ""}
              >
                <FormLabel>Justificación</FormLabel>
                <Input
                  type="text"
                  value={othersProjectNoteValues[activeInput]}
                  onChange={(event) => {
                    let newOthersProjectNoteValues = {
                      ...othersProjectNoteValues,
                    };
                    newOthersProjectNoteValues[activeInput] =
                      event.currentTarget.value;

                    setOthersProjectNoteValues(newOthersProjectNoteValues);
                  }}
                />
                {othersProjectNoteValues[activeInput] === "" && (
                  <FormErrorMessage>
                    Una justificación es obligatoria
                  </FormErrorMessage>
                )}
              </FormControl>
              <Button
                type="submit"
                size="md"
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Guardar
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalOthersReason;
